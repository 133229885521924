import React from "react";
import Carousel from "react-elastic-carousel";
import FirstProject from "../Assests/FirstProject.png";
import SecondProject from "../Assests/SecondProject.png";
import ThirdProject from "../Assests/ThirdProject.png";
import FourthProject from "../Assests/FourthProject.png";


const Projects = (props) => {
  const MyProjects = [
    {
      name: "Movie Browsing",
      image: FirstProject,
      overview:
        "A movie library app that provides the user many ways of browsing for a movie. React, Bootstrap and The Movie Database API were used in the development of this application.",
      website: "https://moviebrowsing.netlify.app/",
      github: "https://github.com/Our-Portfolio/movie_browsing",
    },
    {
      name: "Calcumpare",
      image: SecondProject,
      overview:
        "This application features two different calculators. They return information based on custom inputs for real estate compound interest investments. React, Redux, Bootstrap and Figma were used in the development of this application.",
      website: "https://calcumpare.netlify.app/",
      github: "https://github.com/Our-Portfolio/Investment",
    },
    {
      name: "Nutrivurv",
      image: ThirdProject,
      overview:
        "Nutrivurv is an application that helps users take control of a healthier lifestyle. The application makes it easy to track critical health information; whether that is macros, calories, or any other food data that you use in working towards a healthier life. Front end was built using React, Redux and Bootstrap. Back end was built using Postgres, ExpressJs and JWT.",
      website: "https://nutrivurv.netlify.app/",
      github: "https://github.com/Nutrivurv",
      demo: "https://www.youtube.com/watch?v=I3-Z39VTDSs",
    },
    // {
    //   name: "To-do List",
    //   image: FourthProject,
    //   overview:
    //     "To-do List is a simply project that allows the user to keep track of their tasks. It was built using react and class components.",
    //   website: "https://pedantic-goldwasser-38f4b4.netlify.app/",
    //   github: "https://github.com/alesslongaretti/React-Todo",
    // },
  ];

  const breakPoints = [
    { width: 1, itemsToShow: 1, pagination: false },
    { width: 550, itemsToShow: 1, pagination: false },
    { width: 768, itemsToShow: 1, pagination: false },
    { width: 992, itemsToShow: 1, pagination: false },
    { width: 1200, itemsToShow: 2, pagination: false },
  ];

  const carouselRef = React.useRef(null);
  const onNextStart = (currentItem, nextItem) => {
    if (currentItem.index === nextItem.index) {
      // we hit the last item, go to first item
      carouselRef.current.goTo(0);
    }
  };
  const onPrevStart = (currentItem, nextItem) => {
    if (currentItem.index === nextItem.index) {
      // we hit the first item, go to last item
      carouselRef.current.goTo(MyProjects.length);
    }
  };

  return (
    <div className="my-5 py-5 mx-1 mobile-padding">
      <Carousel
        id=""
        breakPoints={breakPoints}
        ref={carouselRef}
        onPrevStart={onPrevStart}
        onNextStart={onNextStart}
        disableArrowsOnEnd={false}
        className="my-5 py-4"
      >
        {MyProjects.map((item, i) => (
          <div
            key={i}
            className=" position-relative d-flex justify-content-center carousel-container project-flip 
             "
          >
            <div className="justify-content-center project-width  project-container desktop-display">
              <h2 className="project-name text-white">{item.name}</h2>
            </div>

            <div className="justify-content-center project-width  text-white project-container mobile-display ">
              <div className="project-name">
                <h2 className=" text-white py-3">{item.name} </h2>
                <a
                  href={item.website}
                  className="project-button px-2 py-1 m-1"
                  target="_blank"
                >
                  Visit Website
                </a>
                <a
                  href={item.github}
                  className="project-button px-2 py-1 m-1 my-4"
                  target="_blank"
                >
                  <i className="fa fa-github  text-white"> View Code</i>
                </a>
                <div className="mt-2">{item.demo ? (
                  <a
                    href={item.demo}
                    className="project-button px-2 py-1 m-1"
                    target="_blank"
                  >
                    Demo
                  </a>
                ) : (
                  ""
                )}</div>
              </div>
            </div>

            <img
              className="img-fluid project-width  image-projects "
              src={item.image}
              alt={item.name}
            />

            <div className="project-overview project-width  px-5 py-5 text-white">
              <div>
                <h3 className="pb-4">{item.name}</h3>
                <p className="text-justify mx-4">{item.overview}</p>
              </div>
              <div className=" d-flex justify-content-around pt-4 mx-5">
                <a
                  href={item.website}
                  className="project-button px-2 py-1"
                  target="_blank"
                >
                  Visit Website
                </a>
                {item.demo ? (
                  <a
                    href={item.demo}
                    className="project-button px-2 py-1"
                    target="_blank"
                  >
                    Demo
                  </a>
                ) : (
                  ""
                )}
                <a
                  href={item.github}
                  className="project-button px-2 py-1"
                  target="_blank"
                >
                  <i className="fa fa-github  text-white"> View Code</i>
                </a>
              </div>
            </div>
          </div>
        ))}
      </Carousel>
    </div>
  );
};

export default Projects;
