import React from "react";
import About from "./About";
import Projects from "./Projects";
import Footer from "./Footer";
import Alessandra from "../Assests/Alessandra.png"


const Home = () => {
    return (
        <div>
            <div className=" App-margin ">
                <img alt="Alessandra Longaretti" src={Alessandra}
                className=" img-responsive main-image rounded-image"
                width="40%"/>
                <h3 className="mt-5 " >Hi, I'm Alessandra!</h3>
                <h2 className="mt-4 main-color">Full-Stack Web Developer</h2>
            </div>
            <div>
                <About/>
            </div>
            <div>
                <Projects />
            </div>
            <Footer />
        </div>
    )
};

export default Home;