import React from "react";

const Footer = () => {
  return (
    <footer class="text-center text-lg-start main-backgroundcolor mt-5">
      <div class="text-center p-3 text-light">
        © 2021 Copyright: 
        <span class="text-light"> Alessandra Longaretti</span>
      </div>
    </footer>
  );
};

export default Footer;