import React from "react";
import { NavLink } from "react-router-dom";
import { Nav, Navbar } from "react-bootstrap";

const Navigation = () => {
  return (
    <nav className="navbar-expand-md navbar navbar-inverse">
      <div className="container-fluid"><NavLink to="/" className="navbar-brand">
            <h3 className="main-color pt-2">Alessandra Longaretti</h3>
          </NavLink>
        <ul className=" collapse navbar-collapse nav  ">
          <li className="nav-link mt-1">
            <a href="https://github.com/alesslongaretti" className="" target="_blank">
              <i className="fa fa-github  text-dark "></i>
            </a>
          </li>
          <li className="nav-link mt-1">
            <a
              href="https://www.linkedin.com/in/alessandra-longaretti-732471195/"
              className=""
              target="_blank"
            >
              <i className="fa fa-linkedin  text-dark "></i>
            </a>
          </li>
          <li className="nav-link mt-1">
            <a href="https://twitter.com/_AlessandraL_" target="_blank">
              <i className="fa fa-twitter  text-dark"></i>
            </a>
          </li>
        </ul>
        <ul className="navbar-nav nav navbar-right contact-link pt-2">
          <li className="nav-link">
            <NavLink to="/contact">
              <h3 className="main-color">Contact Me</h3>
            </NavLink>
          </li>
        </ul>
      </div>
    </nav>
  );
};

export default Navigation;