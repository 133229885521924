import React from 'react';
import './App.css';
import { Route, Switch } from "react-router-dom";
import Home from "./Components/Home";
import Contact from "./Components/Contact"
import Navigation from "./Components/Navigation"

function App() {
  return (
    <div className="App">
      <Navigation/>
    <Switch>
      <Route exact path="/" component={Home} />
      <Route path="/contact" component={Contact} />
    </Switch>


    </div>
  );
}

export default App;