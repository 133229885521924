import React from "react";
import emailjs from "emailjs-com";

const Contact = () => {
  function sendEmail(e) {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_s0v79hd",
        "template_noa35ci",
        e.target,
        "user_hlAN2hNjkivZQMHGYIDOv"
      )
      .then(
        (result) => {
          console.log(result.text);
          alert("Email sent successfully");

        },
        (error) => {
          console.log(error.text);
        }
      );
    e.target.reset();
  }
  return (
    <div className="contact-background d-flex justify-content-center flex-column">
      <div className="contact-box mx-5 py-5">
        <h1 className="main-color">Contact Me</h1>
        <form className="d-flex flex-column align-items-center mx-5" onSubmit={sendEmail}>

          <input placeholder="Subject" className="app-form-control m-5" />

          <input placeholder="Name" type="text" className="app-form-control m-5" required />

          <input  placeholder="Email" type="email" className="app-form-control m-5" required />

          <input  placeholder="Message" type="text" className="app-form-control m-5 " required />

          <input className=" text-white main-backgroundcolor px-5 py-2 rounded" type="submit" value="Send" />
        </form>
      </div>
    </div>
  );
};

export default Contact;
