import React from "react";

const About = () => {
  return (
    <div className="about-background pt-5">
      <div className="d-flex justify-content-around flex-column flex-lg-row about-container ">
        <div
          className="mx-lg-5 mb-lg-5 mt-lg-4  m-auto text-light about-cards card-size "
          style={{ height: "30rem", width: "50%"  }}
        >
          <h4 className="mx-5 mb-5 mt-4">About Me</h4>
          <h5 className="text-justify m-4 about-line">
            I am a 27 year old Full Stack Developer who loves problem solving
            and creating visually pleasing websites. I started attending Lambda School on September of 2019 to study their Full-Stack
            program. Ever since, I've worked on multiple projects which have helped me grow both as a developer and a team-member.
            I am excited to start working in the industry and be an asset to my place of work.
          </h5>
        </div>
        <div
          className="mx-lg-5 mb-lg-5 mt-lg-4 m-auto text-light about-cards card-size"
          style={{ height: "30rem", width: "50%" }}
        >
          <h4 className="mx-5 mb-5 mt-4">Skills</h4>
          <div className="mx-4">
          <div className="d-flex flex-wrap text-justify font-weight-bold">
            <p className="mr-3">HTML</p>
            <p className="mr-3"> | </p>
            <p className="mr-3">JavaScript</p>
            <p className="mr-3"> | </p>
            <p className="mr-3">React</p>
            <p className="mr-3"> | </p>
            <p className="mr-3">Redux</p>
            <p className="mr-3"> | </p>
            <p className="mr-3">CSS</p>
            <p className="mr-3"> | </p>
            <p className="mr-3">React Hooks</p>
            <p className="mr-3"> | </p>
            <p className="">Bootstrap</p>
          </div>
          <div className="d-flex flex-wrap text-justify mt-4 font-weight-bold">
            <p className="mr-3">Python</p>
            <p className="mr-3"> | </p>
            <p className="mr-3">Node.js</p>
            <p className="mr-3"> | </p>
            <p className="mr-3">Express</p>
            <p className="mr-3"> | </p>
            <p className="mr-3">RESTful API</p>
          </div>
          <div className="d-flex flex-wrap text-justify mt-4 font-weight-bold">
            <p className="mr-3">Jest</p>
            <p className="mr-3"> | </p>
            <p className="mr-3">Cypress</p>
            <p className="mr-3"> | </p>
            <p className="mr-3">Heroku</p>
            <p className="mr-3"> | </p>
            <p className="mr-3">Github Pages</p>
            <p className="mr-3"> | </p>
            <p className="mr-3">Netlify</p>
            <p className="mr-3"> | </p>
            <p className="mr-3">Vercel</p>
            <p className="mr-3"> | </p>
            <p className="mr-3">Git</p>
            <p className="mr-3"> | </p>
            <p className="mr-3">Trello</p>
          </div>
          </div>
        </div>
      </div>
      <div className="d-flex justify-content-center ">
        <h3
          className="bg-white text-center  pt-4 projects-header"
          style={{ height: "7rem", width: "20rem" }}
        >
          My Projects
        </h3>
      </div>
    </div>
  );
};

export default About;
